import React, { useState, useRef, useEffect } from 'react'
import { Collapse } from 'reactstrap'
import MarkdownToHtml from '../MarkdownToHtml'
import { ClickableIcon, QuestionText, QuestionWrapper, AnswerWrapper, ItemWrapper, MainWrapper } from './styled'

export const Question = ({ item, id, isCollapsed, setCollapse }) => (
  <QuestionWrapper onClick={() => setCollapse(!isCollapsed)} {...{ isCollapsed }}>
    <QuestionText>{item.question}</QuestionText>
    {
      isCollapsed
        ? <ClickableIcon onClick={() => setCollapse(!isCollapsed)} className='fa fa-angle-up' />
        : <ClickableIcon onClick={() => setCollapse(!isCollapsed)} className='fa fa-angle-down' />
    }
  </QuestionWrapper>
)

export const Answer = ({ item, id, isCollapsed }) => (
  <Collapse isOpen={isCollapsed}>
    <AnswerWrapper>
      <MarkdownToHtml content={item.answer_markdown} />
    </AnswerWrapper>
  </Collapse>
)

function convertToUrlHash(question) {
  return '#' + question.toLowerCase().replace(/\s+/g, '-');
}

export const AccordionFaqItem = ({ item, id, hash }) => {
  const [isCollapsed, setCollapse] = useState(hash === convertToUrlHash(item.question))

  const itemRef = useRef(null);
  useEffect(() => {
    if (isCollapsed && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isCollapsed]);


  return (
    <ItemWrapper ref={itemRef}>
      <Question {...{ item, id, isCollapsed, setCollapse }} />
      <Answer {...{ item, id, isCollapsed }} />
    </ItemWrapper>
  )
}

export default ({ items, hash }) => (
  <MainWrapper>
    {items.map((item, id) => <AccordionFaqItem item={item} key={id} id={id} hash={hash} />)}
  </MainWrapper>
)
